import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import DatePicker from '../utils/DatePicker';
import UploadImage from '../utils/UploadImage';
import {
  convertTimeStampToHumanReadable,
  getSnapshotFromEndpoint,
  findFirstAndLastName,
  WarningMessage, ErrorMessage, Toast
} from '../utils/HelpfulFunction';
import {sortPlayersForAdmin} from 'myshared';
import ReactPlayer from 'react-player';
import swal from "sweetalert2";

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
          racer_select: {},
          ticketList: [],
          users: [],
          racerList: [],
          schedule_game_time: new Date(),
          schedule_end_game_time: new Date(),
          schedule_game: false,
          racersAdded: [],
          losingRewardsAdded: [],
          emailVariables: {},
          videoState: 0,
          rewardsAdded: [],
          rewardAmount: 0,
          codes: false,
          codesArray: '',
          qrCodes: false,
          userPicks: [],
          tenantRules: {},
          activateGame: {},
          currentGame: null,
          modal: false,
          gameName: null,
          userGameHistory: [],
          loading: true,
          wizardLocation: 'first',
          showStats: true,
          advanceSettings: false,
          gamesList: [],
          emailsToSend: {},
          randomOrder: false,
          losingRandomOrder: false,
          showWinning: true,
          gameTiming: "immediate"
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleRacerVideo = this.toggleRacerVideo.bind(this);
    }

    componentDidMount() {
      const vm = this;
      this.idKeyRef = base.fetch(`id_key`, {
        context: this,
        then(data){
          this.setState({
            id_key: data
          })
        }
      });
      this.racerListRef = base.bindToState(`racerList`, {
        context: this,
        state: 'racerList',
        asArray: true
      });
      this.emailVariablesRef = base.bindToState(`emailVariables`, {
        context: this,
        state: 'emailVariables',
      });
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      this.userAnswersRef = appDatabasePrimaryFunctions.ref("userPicks").on('value', function(snapshot){
        vm.setState({
          userAnswerCount: snapshot.numChildren()
        })
      })
      this.currentTasksRef = base.syncState(`tasks/activateGame`, {
        context: this,
        state: 'activateGame',
      });
      this.currentGameRef = base.syncState(`currentGame`, {
        context: this,
        state: 'currentGame',
        then(err){
          this.setState({
            loading: false,
          }, () => {
            if(!err && this.state.currentGame && this.state.currentGame.id){
              vm.getRedemptions(this.state.currentGame.id);
            }
          })
          if(!err){
            this.setGameToWatch(this.state.currentGame.id)
          }
        },
        onFailure(err){
          // console.log(err)
          this.setState({
            loading: false,
          })
        }
      })
      this.ticketListRef = base.bindToState(`rewardsList`, {
        context: this,
        state: 'ticketList',
        asArray: true
      });
    }

    async getRedemptions(gameId) {
    if (!gameId) return;
    if(this.redeemedPrizeRef){
      base.removeBinding(this.redeemedPrizeRef);
    }
    this.redeemedPrizeRef = base.listenTo(`redeemedPrize`, {
      context: this,
      queries: {
        orderByChild: 'gameId',
        equalTo: gameId
      },
      then(data){
        let countOfWinners = 0;
        for(const i in data){
          if(data[i].redeemed){
            countOfWinners++;
          }
        }
        this.setState({
          redemptions: countOfWinners
        })
      },
      onFailure(err){
        console.log(err)
      }
    })
  }

    downloadUsers(){
      this.setState({loading: true});
      const vm = this;
      let csv = 'Email,First Name,Last Name,Zip Code,Birthday,Phone Number,Opt-In,Opt-In 2,Pick,Correct,Time Picked,Reward,Code,Has been Redeemed\n';
      Promise.all([getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
        let users = {};
        if(snapshots[0].exists() && snapshots[0].val()){
          users = snapshots[0].val();
        }
        const currentUsersState = await sortPlayersForAdmin(0, null, appDatabasePrimaryFunctions, vm.state.currentGame, process.env.REACT_APP_IS_MLB_TEAM === "true");
        currentUsersState.forEach(function(row) {
          let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
          csv += `"${row.email || row.uid || ""}",`;
          csv += `"${firstName || ""}",`;
          csv += `"${secondName || ""}",`;
          csv += `"${users[row.uid].zipCode || ""}",`;
          csv += `"${users[row.uid].birthday || ""}",`;
          csv += `"${users[row.uid].phoneNumber || ""}",`;
          csv += `"${users[row.uid].optIn || ""}",`;
          csv += `"${users[row.uid].optInTwo || ""}",`;
          csv += `"${row.pick || ""}",`;
          csv += `"${row.pickCorrect ? "Yes" : "No"}",`;
          csv += `"${convertTimeStampToHumanReadable(row.timeStamp)}",`;
          csv += `"${row.rewardSent || "NONE"}",`;
          csv += `"${row.code || "-"}",`;
          csv += `"${row.isRedeemed ? "true" : "false"}"\n`;
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
        hiddenElement.download = "current_game_users_" + date_got;
        hiddenElement.click();
        vm.setState({loading: false});
      })
    }

    setGameToWatch(id){
        if(this.userGameHistoryRef){
          appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        if(id){
          const vm = this;
          this.userGameHistoryRef = appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(id).equalTo(id).on('value', function(snapshot){
            vm.setState({
              userGameHistory: snapshot.numChildren()
            })
          })
        }
      }

    componentWillUnmount() {
      base.removeBinding(this.racerListRef);
      base.removeBinding(this.currentGameRef);
      base.removeBinding(this.ticketListRef);
      base.removeBinding(this.emailVariablesRef);
      base.removeBinding(this.tenantVariablesRef);
      base.removeBinding(this.tenantRulesRef);
      if(this.userGameHistoryRef){
        appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
      }
      appDatabasePrimaryFunctions.ref('userAnswers').off('value', this.userAnswersRef);
      base.removeBinding(this.currentTasksRef);
    }

    async createGame(){
      const createGameObject = {};
      const gameId = this.state.editingGame ? this.state.editingGame : appDatabasePrimaryFunctions.ref().push().key;
      const racersAdded = this.state.racersAdded;
      const rewardsAdded = this.state.rewardsAdded || [];
      const losingRewardsAdded = this.state.losingRewardsAdded || [];
      const futureGames = this.state.futureGamesList || [];
      const gameName = this.state.gameName;
      let sendLosingEmails = this.state.sendLosingEmails;
      if(losingRewardsAdded.length > 0){
        sendLosingEmails = true;
      }
      const gameStartTime = this.state.schedule_game_time;
      const gameEndTime = this.state.schedule_end_game_time;
      const scheduledGame = this.state.schedule_game;
      let pickWinner = this.state.pickWinner || false;
      let randomWinner = this.state.randomWinner || false;
      let gameTiming = this.state.gameTiming;
      if(gameTiming === "manual"){
        randomWinner = false;
      }
      if(randomWinner){
        pickWinner = false;
      }
      let winningRacer = null;
      const currentTimeSeconds = Math.floor(Date.now());
      const inputtedDateSeconds = gameStartTime.getTime();
      let inputtedEndDateSeconds;
      if(gameEndTime && gameTiming === "immediate"){
        inputtedEndDateSeconds = gameEndTime.getTime();
      }
      if(!gameName){
        await ErrorMessage.fire({
            title: "Hold On!",
            text: "You must give the game a name!",
            confirmButtonText: 'Ok'
          })
        return;
      }
      if(scheduledGame){
        if(inputtedDateSeconds <= currentTimeSeconds){
          await ErrorMessage.fire({
              title: "Hold On!",
              text: "You have marked that you are scheduling a game but it is before now!",
              confirmButtonText: 'Ok'
            })
          return;
        } else if(!inputtedDateSeconds) {
          await ErrorMessage.fire({
            title: "Hold On!",
            text: "You have marked that you are scheduling but no game time entered!",
            confirmButtonText: 'Ok'
          })
          return;
        } else if(inputtedEndDateSeconds && inputtedEndDateSeconds <= inputtedDateSeconds){
          await ErrorMessage.fire({
            title: "Hold On!",
            text: "If you have an end time it must be in the future",
            confirmButtonText: 'Ok'
          })
          return;
        }
        for(const fGameIndex in futureGames){
          const futureGame = futureGames[fGameIndex];
          if(inputtedDateSeconds === futureGame.activateGame.performAt && this.state.editingGame !== futureGame.id){
            await ErrorMessage.fire({
              title: "Hold On!",
              text: "You already have a scheduled game at that time!",
              confirmButtonText: 'Ok'
            })
            return;
          }
        }
      }
      if(racersAdded && racersAdded.length < 2){
        await ErrorMessage.fire({
            title: "Hold On!",
            text: `Not enough choices added. You need to add at least 2 choices to the game!`,
            confirmButtonText: 'Ok'
          })
        return;
      }
      for(let racer in racersAdded){
        if(racersAdded[racer].winner && !randomWinner){
          winningRacer = racersAdded[racer].id;
        }
      }
      if(!winningRacer && !pickWinner && !randomWinner){
        await ErrorMessage.fire({
            title: "Hold On!",
            text: "No winner is selected!",
            confirmButtonText: 'Ok'
          })
        return;
      }
      let readyToUploadPrizes = null;
      let hasRewardWithAutoRedemptions = false;
      if(rewardsAdded.length !== 0){
        let totalAmount = 0;
        readyToUploadPrizes = {};
        for(const rewardIndex in rewardsAdded){
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key] = rewardsAdded[rewardIndex].reward;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['amount'] = parseInt(rewardsAdded[rewardIndex].amount);
          totalAmount+=parseInt(rewardsAdded[rewardIndex].amount);
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codes'] = rewardsAdded[rewardIndex].codes;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['qrCodes'] = rewardsAdded[rewardIndex].qrCodes || false;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codesArray'] = rewardsAdded[rewardIndex].codesArray || [];
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['used'] = 0;
          readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['isRedeemable'] = rewardsAdded[rewardIndex].reward.isRedeemable || false;
          if(readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['isRedeemable']){
            hasRewardWithAutoRedemptions = true;
          }
        }
        readyToUploadPrizes["totalRewards"] = totalAmount;
        readyToUploadPrizes["totalRewardsUsed"] = 0;
        readyToUploadPrizes["randomPrizeOrder"] = this.state.randomOrder;
      }
      let readyToUploadLosingPrizes = null;
      if(losingRewardsAdded.length !== 0){
        let totalAmount = 0;
        readyToUploadLosingPrizes = {};
        for(const losingRewardIndex in losingRewardsAdded){
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key] = losingRewardsAdded[losingRewardIndex].reward;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['amount'] = parseInt(losingRewardsAdded[losingRewardIndex].amount);
          totalAmount+=parseInt(losingRewardsAdded[losingRewardIndex].amount);
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['codes'] = losingRewardsAdded[losingRewardIndex].codes;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['qrCodes'] = losingRewardsAdded[losingRewardIndex].qrCodes || false;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['codesArray'] = losingRewardsAdded[losingRewardIndex].codesArray || [];
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['used'] = 0;
          readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['isRedeemable'] = losingRewardsAdded[losingRewardIndex].reward.isRedeemable || false;
          if(readyToUploadLosingPrizes[losingRewardsAdded[losingRewardIndex].reward.key]['isRedeemable']){
            hasRewardWithAutoRedemptions = true;
          }
        }
        readyToUploadLosingPrizes["totalRewards"] = totalAmount;
        readyToUploadLosingPrizes["totalRewardsUsed"] = 0;
        readyToUploadLosingPrizes["randomPrizeOrder"] = this.state.losingRandomOrder;
      }
      createGameObject.racers = racersAdded;
      createGameObject.videoState = 0;
      createGameObject.status = 0;
      createGameObject.sentEmail = false;
      createGameObject.rewards = readyToUploadPrizes;
      createGameObject.losingRewards = readyToUploadLosingPrizes;
      createGameObject.pickWinner = pickWinner;
      createGameObject.randomWinner = randomWinner;
      createGameObject.winner = winningRacer;
      createGameObject.gameName = gameName;
      createGameObject.gameTiming = gameTiming;
      createGameObject.active = false;
      createGameObject.sendLosingEmails = sendLosingEmails || false;
      createGameObject.hasRewardWithAutoRedemptions = hasRewardWithAutoRedemptions;
      let activateGame = {};
      let shouldReplaceCurrentGame  = true;
      const currentGame = this.state.currentGame || {};
      if(scheduledGame){
        activateGame['performAt'] = inputtedDateSeconds;
        if(inputtedEndDateSeconds){
          activateGame['endAt'] = inputtedEndDateSeconds;
        }
        activateGame['status'] = "scheduled";
        if(currentGame.gameName){
          shouldReplaceCurrentGame = false
          createGameObject['activateGame'] = activateGame
        }
      } else {
        activateGame = null
        createGameObject['activateGame'] = activateGame
      }

      createGameObject.timeStamp = Date.now()
      createGameObject.id = gameId;
      const vm = this;
      this.setState({
        loading: true
      })
      if(shouldReplaceCurrentGame){
        const onSaveAndClearSuccess = await this.saveAndClearGame(createGameObject, createGameObject.id);
        if(!onSaveAndClearSuccess){
          this.setState({loading: false});
          await ErrorMessage.fire({
            title: "Hold On!",
            text: 'Failed to clear out old game, please try again',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
          return;
        }
      } else if(createGameObject.id) {
        base.post('futureGamesList/'+ createGameObject.id, {
          data: createGameObject,
          async then(err){
            if(!err){
              let futureGameList;
              if(vm.state.showGames){
                futureGameList = await vm.getFutureGames();
              }
              vm.setState({
                futureGamesList: futureGameList,
                editingGame: false,
                modal: false,
                loading:false
              })
              swal({
                title: `Game ${vm.state.editingGame ? "Updated!" : "added to future games!"}`,
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
            }
          }
        })
      }
      this.setState({
        modal: false,
        loading: false
      })
    }

    async saveAndClearGame(futureGame, futureGameId){
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      this.setState({loading: true});
      let fetchObject = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify({futureGame: futureGame, futureGameKey: futureGameId})
      }
      let response;
      try{
        response = await fetch(url, fetchObject);
        return !(!response || response.error);
      } catch (e) {
        console.log("ERROR: ", e);
        return false;
      }
    }

    async makeFutureGameCurrent(gameToSetCurrent){
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/saveAndClearGame`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/saveAndClearGame`;
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      this.setState({loading: true});
      let fetchObject = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify({futureGame: gameToSetCurrent, futureGameKey: gameToSetCurrent.id})
      }
      let response;
      try{
        response = await fetch(url, fetchObject);
      } catch (e) {
        this.setState({loading: false});
        console.log("ERROR: ", e);
        return false;
      }
      const responseJson = await Promise.resolve(response.json());
      if(responseJson.error){
        this.setState({loading: false});
        await ErrorMessage.fire({
          title: 'Oh No!',
          text: "Something went wrong, please try again!"
        })
        return;
      }
      const futureGameList = await this.getFutureGames();
      await this.getRedemptions(gameToSetCurrent.id);
      this.setState({
        futureGamesList: futureGameList,
        loading:false
      })
      await Toast.fire({
        title: 'Game made current!'
      })
    }

    async removeGameFromFutureList(gameToRemoveId){
      var futureGameList = this.state.futureGamesList;
      for(var gameIndex in futureGameList){
        var futureGame = futureGameList[gameIndex];
        if(futureGame.id === gameToRemoveId){
          futureGameList.splice(gameIndex, 1)
          if(gameToRemoveId){
            base.remove('futureGamesList/'+gameToRemoveId)
          }
        }
      }
      this.setState({
        futureGamesList: futureGameList
      })
    }

    async duplicateGame(gameToDuplicate) {
      let showWarning = false;

      const checkForWarning = (rewards) => {
        for (const key in rewards) {
          if (rewards.hasOwnProperty(key)) {
            const reward = rewards[key];
            if (reward.codes || (reward.linksArray && reward.linksArray.length > 0)) {
              return true;
            }
          }
        }
        return false;
      };

      if (gameToDuplicate.rewards && checkForWarning(gameToDuplicate.rewards)) {
        showWarning = true;
      }

      if (!showWarning && gameToDuplicate.losingRewards && checkForWarning(gameToDuplicate.losingRewards)) {
        showWarning = true;
      }

      if (showWarning) {
        await swal({
          title: 'WARNING!',
          text: 'This game has codes or links and those are not available to be duplicated.  Make sure to add in any codes or links you want to add with any prize.',
          type: 'warning',
          confirmButtonText: 'OK',
        });
      }

      this.setState({
        modal: true,
        wizardLocation: "first",
        gameName: gameToDuplicate.gameName,
        schedule_game_time: new Date(gameToDuplicate.activateGame.performAt),
        schedule_end_game_time: new Date(gameToDuplicate.activateGame.endAt),
        racersAdded: gameToDuplicate.racers,
        schedule_game: true,
        editingGame: false,
        randomWinner: gameToDuplicate.randomWinner,
      }, () => {
        const addRewards = (rewards, stateKey) => {
          if (rewards && typeof rewards === 'object') {
            const rewardsAdded = Object.keys(rewards).map(key => {
              const reward = rewards[key];
              if (typeof reward === 'object') {
                const rewardToSave = this.state.ticketList.find(ticket => ticket.key === reward.id || ticket.id === reward.id);
                return {
                  reward: rewardToSave,
                  amount: reward.amount,
                  codes: false,
                  links: null
                };
              }
              return null;
            }).filter(Boolean);
            this.setState({
              [stateKey]: rewardsAdded,
              [`randomOrder`]: rewards.randomPrizeOrder,
            });
          }
        };

        addRewards(gameToDuplicate.rewards, 'rewardsAdded');
        addRewards(gameToDuplicate.losingRewards, 'losingRewardsAdded');
      });
    }

    editGame(gameToEdit){
        this.setState({
          modal: true,
          wizardLocation: "first",
          gameName: gameToEdit.gameName,
          schedule_game_time: new Date(gameToEdit.activateGame.performAt),
          schedule_end_game_time: new Date(gameToEdit.activateGame.endAt),
          racersAdded: gameToEdit.racers,
          editingGame: gameToEdit.id,
          schedule_game: true,
          randomWinner: gameToEdit.randomWinner,
        }, () => {
          const addRewards = (rewards, stateKey) => {
            if (rewards && typeof rewards === 'object') {
              const rewardsAdded = Object.keys(rewards).map(key => {
                const reward = rewards[key];
                if (typeof reward === 'object') {
                  const rewardToSave = this.state.ticketList.find(ticket => ticket.key === reward.id || ticket.id === reward.id);
                  return {
                    reward: rewardToSave,
                    amount: reward.amount,
                    codes: reward.codes,
                    links: reward.links,
                    codesArray: reward.codesArray,
                    linksArray: reward.linksArray,
                    pinsArray: reward.pinsArray,
                    qrCodes: reward.qrCodes
                  };
                }
                return null;
              }).filter(Boolean);
              this.setState({
                [stateKey]: rewardsAdded,
                [`randomOrder`]: rewards.randomPrizeOrder,
              });
            }
          };

          addRewards(gameToEdit.rewards, 'rewardsAdded');
          addRewards(gameToEdit.losingRewards, 'losingRewardsAdded');
        });
      }

    editPrizeThatIsAdded(index, prize, typeOfArray){
      let links, codesArray, linksArray, pinsArray;
      if(prize.linksArray && prize.linksArray.length > 0){
        links = true;
      }
      if(prize.codesArray){
        codesArray = prize.codesArray.join(' ')
      }
      if(prize.linksArray){
        linksArray = prize.linksArray.join(' ')
      }
      if(prize.pinsArray){
        pinsArray = prize.pinsArray.join(' ')
      }
      // Populate form fields with the details of the selected prize
      this.setState({
        [`ticket_select`]: prize.reward.key || prize.reward.id,
        [`rewardAmount`]: prize.amount,
        [`codes`]: prize.codes,
        [`links`]: links,
        [`codesArray`]: codesArray,
        [`linksArray`]: linksArray,
        [`pinsArray`]: pinsArray,
        qrCodes: prize.qrCodes
      });
      this.removeFromToAddArray(index, typeOfArray);
    }

    startGame(){
      var currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = true
        currentGame.timeStamp = Date.now()
        this.setState({
          currentGame: currentGame
        })
      }
    }

    stopGame(){
      var currentGame = this.state.currentGame;
      if(currentGame && currentGame.timeStamp){
        currentGame.active = false
        this.setState({
          currentGame: currentGame
        })
      }
    }

    async endGame(){
      const result = await WarningMessage.fire({
        title: 'STOP!',
        text: 'Are you sure you want to do this?  This will send an email to everyone who has earned a prize and end the game!',
        confirmButtonText: 'YES I WANT TO END THE GAME',
      })
      if(!result || !result.value) return;
      const adminRewardsData = {};
      adminRewardsData['isMlbTeam'] = process.env.REACT_APP_IS_MLB_TEAM === "true";
      adminRewardsData['mlbTeamId'] = process.env.REACT_APP_MLB_TEAM_ID;
      adminRewardsData['fromAdmin'] = "fromAdmin";
      const vm = this;
      this.setState({
        loading: true
      })
      let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendPrizesWithLosingPrize`;
      if(process.env.NODE_ENV === "development"){
        url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/sendPrizesWithLosingPrize`;
      }
      const bearerToken = await this.props.currentUser.getIdToken();
      const bearerTokenString = "Bearer " + bearerToken;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': bearerTokenString
        },
        body: JSON.stringify(adminRewardsData)
      }).then(async res => {
        if(res){
          Promise.resolve(res.json()).then(async function (value) {
            if(value && value.result === "finished"){
              const currentGame = vm.state.currentGame;
              currentGame.status = 2;
              currentGame.sentEmail = true;
              vm.setState({
                currentGame,
                loading: false
              })
              await Toast.fire({title: 'Game Ended'})
            }
          })
        } else {
          await ErrorMessage.fire({
            title: 'Something went wrong!  Check your internet connection and try again!',
            type: 'error',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({
            loading: false
          })
        }
      })
    }

    async resetRace(){
      const currentGame = this.state.currentGame;
      const result = await WarningMessage.fire({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will reset peoples picks!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES',
        })
      if(!result || !result.value) return;
      currentGame.status = 0
      currentGame.videoState = 0
      currentGame.sentEmail = false
      currentGame.active = false;
      if(currentGame.pickWinner){
        currentGame.winner = null;
        for(let i in currentGame.racers){
          let racer = currentGame.racers[i];
          racer.winner = null;
        }
      }
      for(const index in currentGame.rewards){
        if(typeof currentGame.rewards[index] === 'object'){
          currentGame.rewards[index].used = 0
        }
      }
      for(const index in currentGame.losingRewards){
        if(typeof currentGame.losingRewards[index] === 'object'){
          currentGame.losingRewards[index].used = 0
        }
      }

      if(currentGame && currentGame.rewards && currentGame.rewards.totalRewardsUsed && currentGame.rewards.totalRewardsUsed > 0){
        currentGame.rewards.totalRewardsUsed = 0;
      }

      if(currentGame && currentGame.losingRewards && currentGame.losingRewards.totalRewardsUsed && currentGame.losingRewards.totalRewardsUsed > 0){
        currentGame.losingRewards.totalRewardsUsed = 0;
      }

      this.setState({
        currentGame: currentGame,
        currentUserPicksState: []
      })
      await base.remove("prizeWon");
      await base.remove("userPicks");
      let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGame.id).equalTo(currentGame.id).once('value');
      history = history.val();
      for(var historyRef in history){
        let refInstance = history[historyRef];
        if(refInstance && refInstance[currentGame.id]){
          await appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGame.id}`).set(null);
        }
      }
      let redemptions = await appDatabasePrimaryFunctions.ref('redeemedPrize').orderByChild("gameId").equalTo(currentGame.id).once('value');
      redemptions = redemptions.val();
      for(let redemption in redemptions) {
        let refInstance = redemptions[redemption];
        if (refInstance && refInstance.gameId === currentGame.id && currentGame.id) {
          appDatabasePrimaryFunctions.ref(`redeemedPrize/${redemption}`).set(null);
        }
      }
      base.post(`emailsSent`, {
        data: null,
        then(err){
          if(!err){
            console.log("reset emails sent")
          }
        }
      })
      await Toast.fire({title: 'Game Reset'});
    }

    handleChange (evt) {
      const target = evt.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
        editingGame: null,
        gameName: "",
        endTime: null,
        startTime: null,
        racersAdded: [],
        rewardsAdded: [],
        losingRewardsAdded: [],
        schedule_game: false,
        schedule_game_time: new Date(),
        schedule_end_game_time: new Date(),
        rewardAmount: 0,
        ticket_select: ''
      });
    }

    toggleRacerVideo() {
      this.setState({
        modalRacerVideo: !this.state.modalRacerVideo,
      });
    }

    navButtonClicked(direction){
      var currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        })
        this.toggleWizardLocation('second', 'first', 'third')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        })
        this.toggleWizardLocation('third', 'first', 'second')
      } else {
        this.setState({
          wizardLocation: 'first'
        })
        this.toggleWizardLocation('first', 'second', 'third')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2){
      this.setState({
        wizardLocation: tabClicked
      })
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
    }

    async switchStatsPrizes(buttonClicked){
      if(buttonClicked === "showStats"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        document.getElementById('showStats').classList.add('active');
        this.setState({
          showStats:true,
          showPrizes:false,
          showGames:false
        })
      }else if(buttonClicked === "showPrizes"){
        document.getElementById('showPrizes').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        document.getElementById('showGames').classList.remove('active');
        this.setState({
          loading: true
        })
        const organizeUserAnswers = await sortPlayersForAdmin(0, '', appDatabasePrimaryFunctions, this.state.currentGame, process.env.REACT_APP_IS_MLB_TEAM === "true");
        this.setState({
          showStats:false,
          showPrizes:true,
          showGames:false,
          loading: false,
          currentUserPicksState: organizeUserAnswers
        })
      } else if(buttonClicked === "showGames"){
        document.getElementById('showPrizes').classList.remove('active');
        document.getElementById('showGames').classList.add('active');
        document.getElementById('showStats').classList.remove('active');
        const futureGameList = await this.getFutureGames();
        this.setState({
          showStats:false,
          showPrizes:false,
          showGames:true,
          futureGamesList: futureGameList
        })
      }
    }

    removeFromToAddArray(index, name){
      let removeArray = this.state[name];
      removeArray.splice(index, 1)
      this.setState({
        [name]: removeArray
      })
    }

    async addRacerToLocalArray(){
      var racersAddedArray = this.state.racersAdded;
      var racerList = this.state.racerList;
      var racerKeys = []
      for(var racerIndex in racersAddedArray){
        racerKeys.push(racersAddedArray[racerIndex].key)
      }
      if(racerKeys.indexOf(this.state.racer_select) !== -1){
        await WarningMessage.fire({
            title: `Already In Game!`,
            text: `This game already includes this item. Try adding another item.`,
            confirmButtonText: 'Ok'
          })
        return;
      }
      var racer = {}
      for(var racerIndex in racerList){
        if(racerList[racerIndex].key === this.state.racer_select){
            racer = racerList[racerIndex]
            racer.id = this.state.racer_select
        }
      }
      if(racer.id){
         racer.status = 0
         racer.progress = 0
         racer.progress = 0

         racersAddedArray.push(racer)
         this.setState({
           racersAdded: racersAddedArray
         })
      } else {
        await WarningMessage.fire({
            title: "Nothing Selected!",
            text: "Make sure to select an option before clicking 'Add'",
            confirmButtonText: 'Ok'
          })
      }
    }

    setAsWinner(e){
      var racersArray = this.state.racersAdded;
      for(var racer in racersArray){
        if(racer == e){
          racersArray[racer].winner = true
        } else {
          racersArray[racer].winner = false
        }
      }
      this.setState({
        racersAdded: racersArray
      })
    }

    async getFutureGames(numberOfResults = 10){
      return await base.fetch('futureGamesList', {
        context: this,
        asArray: true,
        queries: {
          orderByChild: 'activateGame/performAt'
        },
        then(data){
          return data
        }
      })
    }

    showVideo(videoLink){
      this.toggleRacerVideo();
      this.setState({
        videoLink: videoLink
      })
    }

    async addRewardToLocalArray(){
      const rewardAmount = this.state.rewardAmount;
      const rewardId = this.state.ticket_select;
      const codes = this.state.codes;
      let codesArray = this.state.codesArray || "";
      const qrCodes = this.state.qrCodes;
      codesArray = codesArray.replace(/\n/g, " ")
      if(codesArray.trim() === "" && codes){
        await WarningMessage.fire({
          title: 'Hold on!',
          text: 'Must enter codes or uncheck codes box!',
          confirmButtonText: 'Ok'
        })
        return;
      } else if (codes){
        codesArray = codesArray.replace(/  /g, " ")
        codesArray = codesArray.replace(/ /g, ",")
        codesArray = codesArray.replace(/,,/g, ",")
        var totalCodes = codesArray.split(',')

        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        if(isMlbApp){
          let codesTooLong = false;
          for(const individualCode in totalCodes){
            if(totalCodes[individualCode].length > 100){
              codesTooLong = true;
            }
          }
          if(codesTooLong){
            await WarningMessage.fire({
              title: 'Hold on!',
              text: 'Individual codes must be less than 100 characters',
              confirmButtonText: 'Ok'
            })
            return;
          }
        }

        if(totalCodes.length !== parseInt(rewardAmount,10)){
          await WarningMessage.fire({
            title: 'Codes not equal!',
            text: "# of codes must equal number of rewards created. In this case: " +  totalCodes.length + " does not equal " + rewardAmount,
            confirmButtonText: 'Ok'
          })
          return;
        }
      }

      if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
        await WarningMessage.fire({
          title: 'Missing field!',
          text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
          confirmButtonText: 'Ok'
        })
        return;
      }

      for(var ticket in this.state.ticketList){
        if(this.state.ticketList[ticket].key === rewardId){
          var reward = this.state.ticketList[ticket]
          var rewardToAddObject = {};
          rewardToAddObject.reward = reward;
          rewardToAddObject.amount = rewardAmount;
          rewardToAddObject.codes = codes;
          rewardToAddObject.codesArray = totalCodes;
          rewardToAddObject.qrCodes = qrCodes;
        }
      }
      let typeOfArray = "rewardsAdded"
      let array = this.state.rewardsAdded;
      if(!this.state.showWinning){
          array = this.state.losingRewardsAdded;
          typeOfArray = "losingRewardsAdded"
      }
      var rewardsAddedIds = []
      for(var prize in array){
        rewardsAddedIds.push(array[prize].reward.key)
      }
      if(rewardsAddedIds.indexOf(rewardId) === -1){
        array = array.concat(rewardToAddObject)
        this.setState({
          [typeOfArray]: array,
          qrCodes: false,
          codes: false,
          ticket_select: "",
          rewardAmount: 0
        })
      } else {
        await WarningMessage.fire({
          title: 'Prize already added!',
          text: "You have already added this prize",
          confirmButtonText: 'Ok'
        })
      }
    }

    switchWinning(showBoolean) {
      if (showBoolean) {
        document.getElementById('showWinning').classList.add('active');
        document.getElementById('showLosing').classList.remove('active');
      } else {
        document.getElementById('showWinning').classList.remove('active');
        document.getElementById('showLosing').classList.add('active');
      }
      this.setState({
        showWinning: showBoolean
      })
    }

    async setRewardRedeemed(reward, index){
      const result = await WarningMessage.fire({
        title: 'Hold on!',
        text: 'This prizes redemption process is handled automatically by Sqwad, are you sure you want to toggle this?',
        confirmButtonText: 'Toggle'
      })
      if(!result.value){
        return
      }
      let uid = reward.uid || "";
      const currentGame = this.state.currentGame || {};
      const gameId = currentGame.id || "";
      const prizeWonId = reward.prizeWonId || reward.sendPrizeId || "";
      if(!uid || !gameId || !prizeWonId){
        return
      }
      this.setState({
        loading: true
      })
      let valueToSet = false;
      const redeemedPrizeRef = await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).once('value');
      const redeemedPrize = redeemedPrizeRef.val();
      if(redeemedPrize.redeemed){
        await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).update({redeemed: false, timeRedeemed: null});
      } else {
        valueToSet = true;
        await appDatabasePrimaryFunctions.ref('redeemedPrize').child(prizeWonId).update({redeemed: true, timeRedeemed: new Date().getTime()});
      }
      const currentUserPicksState = this.state.currentUserPicksState;
      currentUserPicksState[index].isRedeemed = valueToSet;
      this.setState({
        currentUserPicksState: currentUserPicksState,
        loading: false
      })
    }

    render() {
      let selectedGame = this.state.currentGame || {};
      const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
      const futureGamesList = this.state.futureGamesList || [];
      const userAnswerCount = this.state.userAnswerCount || 0;
      const tenantVariables = this.state.tenantVariables || {};
      let racerList = [];
      let sentEmail;
      let gameActive;
      let startTimeFirstPart = null;
      let startTimeSecondPart = null;
      let endTimeSecondPart = null;
      let endTimeFirstPart = null;
      let showTime = false;
      let gameStatus = 0;
      let hasRewardWithAutoRedemptions = false;
      const rewardsList = [];
      const losingRewardsList = [];
      let rewardsAdded = this.state.rewardsAdded || [];
      if(!this.state.showWinning){
        rewardsAdded = this.state.losingRewardsAdded || [];
      }
      let currentUserPicksState = this.state.currentUserPicksState || [];
      let videoLength;
      let emailsSentButtonText = "Emails Sent!"
      if(selectedGame && selectedGame.gameName){
        for(const i in selectedGame.rewards){
          const reward = selectedGame.rewards[i];
          if(typeof reward === "object"){
            rewardsList.push(reward);
          }
        }
        for(const i in selectedGame.losingRewards){
          const reward = selectedGame.losingRewards[i];
          if(typeof reward === "object"){
            losingRewardsList.push(reward);
          }
        }
        gameActive = selectedGame.active;
        sentEmail = selectedGame.sentEmail;
        racerList = selectedGame.racers || [];
        gameStatus = selectedGame.status;
        for(const racerIndex in racerList){
          const racer = racerList[racerIndex];
          if(racer.winner){
            videoLength = racer.videoLength
          }
        }
        if((rewardsList.length === 0 || tenantVariables.doNotCollectEmail || isMlbApp) && videoLength){
          emailsSentButtonText = "Video Played"
        } else if(rewardsList.length === 0 || tenantVariables.doNotCollectEmail || isMlbApp){
          emailsSentButtonText = "Game Ended"
        }
        if(selectedGame.activateGame){
          startTimeFirstPart = new Date(selectedGame.activateGame.performAt).toLocaleDateString();
          startTimeSecondPart = new Date(selectedGame.activateGame.performAt).toLocaleTimeString();
          if(selectedGame.activateGame.endAt){
            endTimeFirstPart = new Date(selectedGame.activateGame.endAt).toLocaleDateString();
            endTimeSecondPart = new Date(selectedGame.activateGame.endAt).toLocaleTimeString();
          }
          showTime = true;
        }
        hasRewardWithAutoRedemptions = selectedGame.hasRewardWithAutoRedemptions;
      } else {
        selectedGame = null;
        gameActive = false;
        sentEmail = false;
      }

      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                {selectedGame &&
                  <>
                    <div className="col-md-2">
                      <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? '' : 'none', float:'left'}}>
                        <p style={{lineHeight:'50px'}}>Game Is Active</p>
                      </div>
                      <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: gameActive ? 'none' : '', float:'left'}}>
                        <p style={{lineHeight:'50px'}}>Game is Inactive</p>
                      </div>
                    </div>
                    <center className="col-md-1" style={{display: gameActive ? '' : 'none', color:'black'}}>
                      Advanced<input id="advanceSettings" name="advanceSettings" type="checkbox" checked={this.state.advanceSettings} onChange={this.handleChange} />
                    </center>
                    <div className="col-md-2">
                      <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: gameActive ? 'none' : '', float:'left', height:'52px'}}>Start Game</button>
                      <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: gameActive && this.state.advanceSettings ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                    </div>
                    <div className="col-md-2">
                      <div className="col-md-2">
                        <button onClick={() => this.endGame()} className="btn btn-primary btn-lg end-game-button" style={{fontSize: "0.8rem", display: !sentEmail && selectedGame.gameTiming !== "immediate" ? '' : 'none', float:'left', height:'52px' }}>{isMlbApp?"Send Message":"Send Email"}</button>
                      </div>
                      <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: sentEmail ? '' : 'none', float:'left'}}>
                        <p style={{lineHeight:'50px'}}>{emailsSentButtonText}</p>
                      </div>
                    </div>
                    <div className="col-md-2" style={{color:"black", visibility: "hidden"}}>
                      {/*<center>Status: {currentScheduleStatus}</center>*/}
                      {/*<center>Scheduled Time: {currentScheduleTime}</center>*/}
                    </div>
                  </>
                }
                <div className="col-md-3">
                  <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetRace()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                  <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="container">
                <div className="row">
                  <div className="col-md-4" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft:10, marginTop: 10, marginBottom:10, borderRadius: 5, display: !showTime && "none"}}>
                    <div className="row">
                      <div className="col-md-6">
                        <u style={{color:"#353839"}}>Starts</u>
                        <br/>
                        <span style={{fontSize:18}}>{startTimeFirstPart || "No Start Time"}<br/>{startTimeSecondPart}</span>
                      </div>
                      <div className="col-md-6">
                        <u style={{color:"#353839"}}>Ends</u>
                        <br/>
                        <span style={{fontSize:18}}>{endTimeFirstPart || "No End Time"}<br/>{endTimeSecondPart}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft: 10, marginTop:10, marginBottom:10, borderRadius: 5}}>
                    <div className="row">
                      <div className="col-md-12" style={{textAlign:'center'}}>
                        <span style={{fontSize:12}}>Current Game Players</span>
                        <footer className="value-text">{userAnswerCount}</footer>
                      </div>
                    </div>
                  </div>
                  {hasRewardWithAutoRedemptions &&
                    <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginLeft: 10, marginTop:10, marginBottom:10, borderRadius: 5}}>
                      <div className="row">
                        <div className="col-md-12" style={{textAlign:'center'}}>
                          <span style={{fontSize:12}}>Redemptions</span>
                          <footer className="value-text">{this.state.redemptions || 0}</footer>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="" style={{display: selectedGame ? "": "none"}}>
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showPrizes")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Future Games</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                  <div className="export-button-styles btn btn-primary btn-lg download-button" style={{marginBottom:10}} onClick={()=>this.downloadUsers()}>
                    <span className="fa fa-arrow-circle-down"/> Download Participants
                  </div>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>{tenantVariables.doNotCollectEmail || isMlbApp ? "UID" : "Email"}</th>
                        <th>Pick</th>
                        <th>Correct</th>
                        <th>Time Stamp</th>
                        <th>Place</th>
                        <th>Reward</th>
                        <th>Code</th>
                        {hasRewardWithAutoRedemptions &&
                            <th>Redeemed</th>
                        }
                      </tr>
                      {
                        currentUserPicksState.map(function(item,i){
                            const timeStamp = convertTimeStampToHumanReadable(item.timeStamp)
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{tenantVariables.doNotCollectEmail || isMlbApp ? item.uid : item.email}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.pick}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.pickCorrect ? "Yes" : "No"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{timeStamp}</td>
                              <td style={{fontFamily:'Open Sans' }}>{i + 1}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                              <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                              {hasRewardWithAutoRedemptions &&
                                  <td style={{fontFamily: 'Open Sans'}}>
                                    <input type="checkbox" id={i} data-switch="success" checked={item.isRedeemed} onClick={() => this.setRewardRedeemed(item, i)} readOnly disabled={!(item.rewardSent || item.name)}/>
                                    <label htmlFor={i} data-on-label="Yes" data-off-label="No"/>
                                  </td>
                              }
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Rewards</h4>
                        {rewardsList.length > 0 &&
                            rewardsList.map(function(item, index){
                              return (
                                  <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                    <div className="col-md-6">
                                      {item.rewardName}
                                    </div>
                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                      Amount: {item.amount}
                                    </div>
                                  </div>
                              )
                            })
                        }
                        {losingRewardsList && losingRewardsList.length > 0 &&
                            <>
                              <h4 style={{color:'black', marginTop: 10}}>Losing Rewards</h4>
                              {
                                losingRewardsList.map(function(item, index){
                                  return (
                                      <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                        <div className="col-md-6">
                                          {item.rewardName}
                                        </div>
                                        <div className="col-md-6" style={{textAlign:"right"}}>
                                          Amount: {item.amount}
                                        </div>
                                      </div>
                                  )
                                })
                              }
                            </>
                        }
                      </div>
                      <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                        <h4 style={{color:'black'}}>Details</h4>
                        {selectedGame && selectedGame.gameName &&
                          <div style={{textAlign:'left', color:'black'}}>
                            Game Name: {selectedGame.gameName}
                            <br/>
                            Losing Prize: {selectedGame.sendLosingEmails ? "Yes" : "No"}
                            {selectedGame.racers &&
                              selectedGame.racers.map(function(item, index){
                                return (
                                    <div key={index} style={{marginTop: 5}}>
                                      <p>Choice: <img src={item.gifSelectionImage} width="50px" height="auto" alt=""/>{item.racerName} {item.winner && "WINNER!"}</p>
                                      {item.winner &&
                                          <>
                                            <button className="btn btn-primary" style={{marginRight:10}} onClick={()=>this.showVideo(item.shuffleLink)}>Shuffle Video Link</button>
                                            <button className="btn btn-primary" onClick={()=>this.showVideo(item.revealLink)}>Reveal Video Link</button>
                                          </>
                                      }
                                    </div>
                                )
                              }, this)
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{display: this.state.showGames ? 'block' : 'none'}}>
                  <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Make Current Game</th>
                        <th>Duplicate Game</th>
                        <th>Update Game</th>
                        <th>Delete Game</th>
                      </tr>
                      {
                        futureGamesList.map(function(item,i){
                          let winningRacer = null;
                          for(let winnerIndex in item.racers){
                            var racer = item.racers[winnerIndex];
                            if(racer.winner){
                              winningRacer = racer.racerName;
                            }
                          }
                          const dateTime = convertTimeStampToHumanReadable(item.activateGame.performAt)
                          const endTime = convertTimeStampToHumanReadable(item.activateGame.endAt)
                            return <tr key={i}>
                              <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                              <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                              <td style={{fontFamily:'Open Sans' }}>{endTime || "None"}</td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.makeFutureGameCurrent(item)}>MAKE CURRENT</button></td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.duplicateGame(item)}>DUPLICATE</button></td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.editGame(item)}>UPDATE</button></td>
                              <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> {this.state.editingGame ? "Update" : "Create"} Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> Add Picks</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-3">
                                          <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                          <div className="col-sm-9">
                                              <input id="gameName" name="gameName" className="form-control" type="text" value={this.state.gameName} onChange={this.handleChange} placeholder="Example: 10/13 Game Dallas" />
                                          </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-12 col-form-label" htmlFor="name3"> Answer Timing</label>
                                            <div className="col-md-9" onChange={this.handleChange}>
                                              <input type="radio" value="manual" name="gameTiming" checked={this.state.gameTiming === "manual"}/> <span style={{marginRight:10, fontSize:10}}>Manual</span>
                                              <input type="radio" value="immediate" name="gameTiming" checked={this.state.gameTiming === "immediate"}/> <span style={{fontSize:10}}>Immediate</span>
                                              <p className="text-muted2" style={{fontSize:'10px', display: this.state.gameTiming === "manual" ? 'block' : 'none'}}>"Manual" requires a button click to start showing answers. Best for stadiums/live events.</p>
                                              <p className="text-muted2" style={{fontSize:'10px', display: this.state.gameTiming === "immediate" ? 'block' : 'none'}}>"Immediate" shows the answer as soon as the user has finished. The prizes will then be sent if appropriate</p>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                          <label className="col-md-3 col-form-label" htmlFor="name3"> Select Option</label>
                                          <div className="col-md-9">
                                            <select className="form-control" name="racer_select" id="racer_select" value={this.state.racer_select} onChange={this.handleChange}>
                                              <option/>
                                              {
                                                this.state.racerList.map(function(item,index){
                                                  return(
                                                    <option value={item.key} key={index}>{item.racerName}</option>
                                                  )
                                                })
                                              }
                                            </select>
                                          </div>
                                        </div>
                                        {this.state.racersAdded && this.state.racersAdded.length > 0 && this.state.gameTiming === "immediate" &&
                                            <div className="form-check" >
                                              <input value={this.state.randomWinner} className="form-check-input" id="randomWinner" name="randomWinner" type="checkbox" checked={this.state.randomWinner} onChange={this.handleChange} />
                                              <label className="form-check-label" htmlFor="randomWinner">Random Winner</label>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </form>
                            <div className="form-group mb-3">
                                <ol>
                                  {
                                    this.state.racersAdded.map(function(item,index){
                                      return(
                                        <li key={index} style={{margin:10}}> {item.racerName}
                                          <span style={{marginLeft:"40px"}}>
                                            {!this.state.pickWinner && (!this.state.randomWinner || this.state.gameTiming === "manual") &&
                                              <>
                                                <button className="btn btn-success btn-admin" onClick={()=>this.setAsWinner(index)} style={{display: item.winner ? 'none' : ''}}>SET AS WINNER</button>
                                                <span className="btn btn-success btn-admin" style={{display: item.winner ? '' : 'none'}}>WINNER</span>
                                              </>
                                            }
                                            <button className="btn btn-danger btn-admin" style={{marginLeft:"40px"}} onClick={()=>this.removeFromToAddArray(index, "racersAdded")}><span className="fa fa-trash"/></button>
                                          </span>
                                        </li>
                                      )
                                    }, this)
                                  }
                                </ol>
                            </div>
                            <div className="form-group row mb-3" align="center">
                              <div className="col-md-12">
                                <button className="btn btn-primary btn-admin" onClick={()=>this.addRacerToLocalArray()}>Add</button>
                              </div>
                            </div>
                        </div>

                         <div className="tab-pane" id="second">
                            <div className="form-horizontal">
                              <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                <li className="nav-item" onClick={()=> this.switchWinning(true)}>
                                  <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showWinning">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Winning</span>
                                  </a>
                                </li>
                                <li className="nav-item" onClick={()=> this.switchWinning(false)}>
                                  <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showLosing">
                                    <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Losing</span>
                                  </a>
                                </li>
                              </ul>
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                        <div className="col-md-9">
                                          <select className="form-control" name="ticket_select" id="ticket_select" value={this.state['ticket_select']} onChange={this.handleChange}>
                                            <option/>
                                            {
                                              this.state.ticketList.map(function(item,index){
                                                return(
                                                    <option value={item.key} key={index}>{item.rewardName}</option>
                                                )
                                              })
                                            }
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                        <div className="col-md-9">
                                          <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                        </div>
                                      </div>

                                      <div className="form-check" >
                                        <input value={this.state.codes} className="form-check-input" id="codes" name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="codes">Redeem Codes?</label>
                                      </div>

                                      {this.state.codes && !tenantVariables.doNotCollectEmail &&
                                          <div className="form-check" >
                                            <input value={this.state.qrCodes} className="form-check-input" id="qrCodes" name="qrCodes" type="checkbox" checked={this.state.qrCodes} onChange={this.handleChange} />
                                            <label className="form-check-label" htmlFor="qrCodes">Convert Codes To QRCode?</label>
                                          </div>
                                      }

                                      <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                        <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                        <div className="col-md-9">
                                          <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                        </div>
                                      </div>

                                      <div className="form-group row mb-3" align="center">
                                        <div className="col-md-12">
                                          <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                        </div>
                                      </div>

                                      {rewardsAdded && rewardsAdded.length > 1 &&
                                        <div className="form-check" >
                                          <input value={this.state.showWinning ? this.state.randomOrder : this.state.losingRandomOrder} className="form-check-input" id={this.state.showWinning ? "randomOrder" : "losingRandomOrder"} name={this.state.showWinning ? "randomOrder" : "losingRandomOrder"} type="checkbox" checked={this.state.showWinning ? this.state.randomOrder : this.state.losingRandomOrder} onChange={this.handleChange} />
                                          <label className="form-check-label" htmlFor={this.state.showWinning ? "randomOrder" : "losingRandomOrder"}>Give Out In Random Order</label>
                                        </div>
                                      }

                                      <div className="form-group mb-3">
                                        <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                          {
                                            rewardsAdded.map(function(item,index){
                                              let codesText = "No"
                                              if(item.codes){
                                                codesText = "Yes"
                                              }
                                              return(
                                                  <div key={index} className="form-group">
                                                    <li>) {item.amount} - {item.reward.rewardName}; Codes: {codesText}
                                                      <span style={{marginLeft: 60, cursor: 'pointer'}} className="fa fa-pen" onClick={()=>this.editPrizeThatIsAdded(index, item, this.state.showWinning ? "rewardsAdded" : "losingRewardsAdded")}/>
                                                      <span style={{marginLeft: 20, cursor: 'pointer'}} className="fa fa-trash" onClick={()=>this.state.showWinning ? this.removeFromToAddArray(index,"rewardsAdded") : this.removeFromToAddArray(index, "losingRewardsAdded")}/>
                                                    </li>
                                                  </div>
                                              )
                                            }, this)
                                          }
                                        </ol>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="third">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                            <div className="form-check">
                              <input value={this.state.schedule_game} className="form-check-input" id="schedule_game" name="schedule_game" type="checkbox" checked={this.state.schedule_game} onChange={this.handleChange} />
                              <label className="form-check-label" htmlFor="schedule_game">Schedule Game</label>
                            </div>
                            <div className="form-group row mb-3 form_times" style={{display: this.state.schedule_game ? 'flex' : 'none' }}>
                                <label className="col-md-3 col-form-label" htmlFor="schedule_game_time"> Game Start Time</label>
                                <div className="col-md-9">
                                  <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_game_time} onChange={date => this.setState({schedule_game_time: date})}/>
                                </div>
                            </div>
                            <div className="form-group row mb-3 form_times" style={{display: this.state.schedule_game && this.state.gameTiming === "immediate" ? 'flex' : 'none' }}>
                              <label className="col-md-3 col-form-label" htmlFor="schedule_end_game_time"> Game End Time</label>
                              <div className="col-md-9">
                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.schedule_end_game_time} onChange={date => this.setState({schedule_end_game_time: date})}/>
                              </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center">
                                        <h2 className="mt-0">
                                            <i className="mdi mdi-check-all"/>
                                        </h2>
                                        <h3 className="mt-0">Finish</h3>
                                        <div className="row form-group">
                                          <div className="col-md-12">
                                            <p>You are all set to create a game!  Before clicking "{this.state.editingGame ? "Update" : "Create"} Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item"><a href="#" className="btn btn-primary btn-lg previous-wizard-button" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }} onClick={() => this.navButtonClicked('prev')}>Previous</a></li>
                            <li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation !== 'third' ? '' : 'none'}}>Next</a></li>
                            <li className="next list-inline-item float-end"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'third' ? '' : 'none' }}>{this.state.editingGame ? "Update" : "Create"} Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
          <Modal toggle={this.toggleRacerVideo} isOpen={this.state.modalRacerVideo} style={{width: '90%'}} id="modalRacerVideo">
            <ReactPlayer playsinline={false} volume={null} url={this.state.videoLink} muted={false} playing={false} controls={true} width="100%" height="auto"/>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
